import FooterRevamp from './FooterRevamp';
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import API from './API/API';
import DisableSidebar from './DisableSidebar';
import { useForm, Controller } from "react-hook-form";
import Axios from 'axios';
import { Modal } from 'react-bootstrap';
import ModalCommon from './Modal/modal';
import Topbar from './Home/Topbar';

const AddNewAddress = (props) => {

    const { state } = useLocation();

    const [successShow, setSuccessShow] = useState(false);
    const [failureShow, setFailureShow] = useState(false);
    const [message, setMessage] = useState("");

    const handleSuccessShow = () => setSuccessShow(true);
    const handleFaliureShow = () => setFailureShow(true);

    const handleCloseShow = () => {
        if (successShow) {
            setSuccessShow(false);
            navigate('/ManageAllAddress')
        }
        if (failureShow) {
            setFailureShow(false);
        }
    };

    const [show, setShow] = useState(false);
    const [showResult, setShowResult] = useState(true);
    const [address, setAddress] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [addShow, setAddShow] = useState(false);
    const [addaddress, setAddAddress] = useState([]);
    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);
    const navigate = useNavigate();
    const user_id = sessionStorage.getItem('user_id');
    const member_id = sessionStorage.getItem('member_id');


    const [cities, setCities] = useState([]);
    const [addressid, setaddressid] = useState([]);
    const [addresstype, setaddresstype] = useState([]);
    const [pincode, setpincode] = useState('');
    const [address_line_1, setaddress_line_1] = useState();
    const [floor, setFloor] = useState('');
    const [unit, setunit] = useState('');
    const [building, setBuilding] = useState('');
    const [city_id, setcityid] = useState();
    const [city_name, setcityname] = useState();

    const [addpincode, setaddpincode] = useState('');
    const [addaddress_line_1, setaddaddress_line_1] = useState('');

    const [addcity_id, setaddcityid] = useState('');
    const [addcity_name, setaddcityname] = useState();
    const [addfloor, setaddfloor] = useState('');
    const [addunit, setaddunit] = useState('');
    const [addbuilding, setaddbuilding] = useState('');

    const checkPincode = (event) => {

        setpincode(event.target.value)
        if (pincode === '') {
            setaddress_line_1(" ");
        }
        else {
            if (pincode.length === 6) {
                const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
                Axios.get(`${pinCodeBaseUrl}?searchVal=${pincode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
                    if (result.data.results.length === 0) {
                        setMessage("No Such Postal Code,Try Another!");
                        // setFailureAlert(true);
                        handleFaliureShow();
                        setpincode('');
                    } else {
                        setAddress(result.data.results);
                        console.log(address);
                        handleShow();
                    }
                });
            }
        }
    }

    const checkAddPincode = (event) => {

        setpincode(event.target.value)
        if (addpincode === '') {
            setaddaddress_line_1(" ");
        }
        else {
            if (addpincode.length === 6) {
                const pinCodeBaseUrl = process.env.REACT_APP_PINCODE_BASE_URL;
                Axios.get(`${pinCodeBaseUrl}?searchVal=${addpincode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`).then((result) => {
                    if (result.data.results.length === 0) {

                        setMessage("No Such Postal Code,Try Another!");
                        // setFailureAlert(true);
                        handleFaliureShow();
                        setaddpincode('');
                    }
                    else {
                        setAddAddress(result.data.results);
                        console.log(addaddress);
                        handleAddShow();
                    }
                });
            }
        }
    }

    const handleClick = (ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME) => {
        setaddress_line_1(ADDRESS);
    }

    const handleAddClick = (ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME) => {
        setaddaddress_line_1(ADDRESS);
    }

    useEffect(() => {
        API.get('load_cities?state_id=0').then(result => {
            setCities(result.data.result);
        });
    }, [])

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0) {
            setIsSubmit(true);
        }
    }, [formErrors]);


    const validate1 = (address_line_1, pincode) => {
        const errors = {};

        if (!address_line_1) {
            errors.address_line_1 = "Address is required!";
        }
        if (!pincode) {
            errors.pincode = "Postal Code is required";
        }
        else if (pincode.length < 6) {
            errors.pincode = "Postal Code must have 6 digits";
        }
        if (!floor) {
            errors.floor = "Floor is required";
        }
        if (!unit) {
            errors.unit = "Unit is required";
        }

        return errors;
    };

    const validate2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const errors = {};

        switch (name) {
            case "address_line_1":
                if (!value.address_line_1) {
                    errors.address_line_1 = "Address is required!";
                }
                break;
            case "pincode":

                if (!value.pincode) {
                    errors.pincode = "Postal Code is required";
                }
                else if (value.pincode.length < 6) {
                    errors.pincode = "Postal Code must have 6 digits";
                }
                break;
            case "floor":
                if (!value.floor) {
                    errors.floor = "Floor is required";
                }
                break;
            case "unit":

                if (!value.unit) {
                    errors.unit = "Unit is required";
                }
                break
            default:
                break;
        }
        return errors;
    };


    const Addvalidate2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const errors = {};

        switch (name) {
            case "addaddress_line_1":
                if (!addaddress_line_1) {
                    errors.addaddress_line_1 = "Address is required!";
                }
                break;
            case "addpincode":
                if (!addpincode) {
                    errors.addpincode = "Postal Code is required";
                }
                else if (addpincode.length < 6) {
                    errors.addpincode = "Postal Code must have 6 digits";
                }
                break;
            case "addcity_id":

                if (!addcity_id) {
                    errors.addcity_id = "City is required";
                }
                break;


            default:
                break;
        }
        return errors;
    };

    const Addvalidate = (addaddress_line_1, addpincode, addcity_id) => {
        const errors = {};

        if (!addaddress_line_1) {
            errors.addaddress_line_1 = "Address is required!";
        }

        if (!addpincode) {
            errors.addpincode = "Postal Code is required";
        }
        else if (addpincode.length < 6) {
            errors.addpincode = "Postal Code must have 6 digits";
        }
        if (!addcity_id) {
            errors.addcity_id = "City is required!";
        }
        if (!addfloor) {
            errors.addfloor = "Floor is required!";
        }
        if (!addunit) {
            errors.addunit = "Unit is required!";
        }
        return errors;
    };

    const handleError = (e) => {

        setFormErrors(validate2(e));

    }
    const handleAddError = (e) => {

        setFormErrors(Addvalidate2(e));

    }
    const addAddress = () => {
        setShowResult(false);
    }




    const onAddressSubmit = (e) => {
        e.preventDefault()


        setFormErrors(Addvalidate(addaddress_line_1, addpincode, addcity_id, addfloor, addunit, addbuilding));
        if (isSubmit === true) {
            API.post(`add_address`,
                {
                    "address_line_1": addaddress_line_1,

                    "address_type": "common_address",

                    "city_id": addcity_id,
                    "floor": addfloor,
                    "unit": addunit,
                    "building": addbuilding,
                    "latitude": "",
                    "longitude": "",
                    "pincode": addpincode,
                    "member_id": member_id
                }

            ).then(result => {

                if (result.data.is_successful === true) {
                    setIsSubmit(false);
                    // setSuccessAlert(true);
                    setMessage(result.data.message);
                    handleSuccessShow()
                }

                else {
                    setIsSubmit(false);
                    setMessage(result.data.message);
                    // setFailureAlert(true);
                    handleFaliureShow();
                }


            })
                .catch((error) => {
                    setIsSubmit(false);

                    console.log(error.response.data.parameter_errors[0].message);


                })

        }


    }

    return (
        <>

            <div className="st-height-70"></div>
            <div className='st-blue-color'>
                <Topbar />
            </div>
            <section className="st-page-header st-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="st-page-header-text">
                                <h1>Manage Address</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="st-blog-wrap st-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <DisableSidebar />
                        </div>
                        <div className='col-md-9'>
                            <div>
                                <h3>You can add your address details here</h3>
                                <div className="row">

                                    <div className="col-md-8" style={{ marginTop: '10px' }}>

                                        <form method="post" autoComplete="off" onSubmit={onAddressSubmit}>
                                            <div id="AssetAddressmodel">
                                                <div className="form-group row">
                                                    <div className="col-md-3 required">
                                                        <label className="text-right">Postal Code<span style={{ color: 'red' }}>*</span></label>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <input type="text" id="addpincode" name="addpincode" pattern="[0-9]*" onBlur={(e) => { checkAddPincode(e); handleAddError(e) }} maxLength="6" className="form-control ccp per AddrClass" title="Please Enter The Postal Code" autoComplete="off" tabIndex="37" required="" value={addpincode} onChange={(e) => setaddpincode((v) => (e.target.validity.valid ? e.target.value : v))} />
                                                        <span className="formError">{formErrors.addpincode}</span>
                                                    </div>
                                                </div>



                                                <div className="form-group row">
                                                    <div className="col-md-3 required">
                                                        <label className="text-right">Address<span style={{ color: 'red' }}>*</span></label>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <input type="text" id="addaddress_line_1" name="addaddress_line_1" maxLength="100" className="form-control ccp per AddrClass" title="Please Enter The Address" autoComplete="off" tabIndex="32" required="" value={addaddress_line_1} onBlur={(e) => handleAddError(e)} onChange={(e) => { setaddaddress_line_1((v) => (e.target.validity.valid ? e.target.value : v)) }} />
                                                        <span className="formError">{formErrors.addaddress_line_1}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-3 required">
                                                        <label className="text-right">Floor<span style={{ color: 'red' }}>*</span></label>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <input type="text" id="addfloor" name="addfloor" maxLength="100" className="form-control ccp per AddrClass" title="Please Enter The Floor" autoComplete="off" tabIndex="32" required="" value={addfloor} onBlur={(e) => handleError(e)} onChange={(e) => { setaddfloor(e.target.value) }} />
                                                        <span className="formError">{formErrors.addfloor}</span>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-3 required">
                                                        <label className="text-right">Unit<span style={{ color: 'red' }}>*</span></label>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <input type="text" id="addunit" name="addunit" maxLength="100" className="form-control ccp per AddrClass" title="Please Enter The Unit" autoComplete="off" tabIndex="32" required="" value={addunit} onBlur={(e) => handleError(e)} onChange={(e) => { setaddunit(e.target.value) }} />
                                                        <span className="formError">{formErrors.addunit}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-3 required">
                                                        <label className="text-right">Building</label>
                                                    </div>

                                                    <div className="col-md-9">
                                                        <input type="text" id="building" name="building" maxLength="100" className="form-control ccp per AddrClass" title="Please Enter The Building" autoComplete="off" tabIndex="32" required="" value={building} onBlur={(e) => handleError(e)} onChange={(e) => { setBuilding(e.target.value) }} />
                                                        <span className="formError">{formErrors.building}</span>
                                                    </div>
                                                </div>



                                                <div className="form-group row">

                                                    <div className="col-md-3 required">
                                                        <label className="text-right">City<span style={{ color: 'red' }}>*</span></label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <select name="addcity_id" id="addcity_id" type="number" maxLength="50" className="form-control ccp per AddrClass" title="Please Enter The City name" autoComplete="off" tabIndex="39" required="" onBlur={(e) => handleAddError(e)} onChange={(e) => { setaddcityid((v) => (e.target.validity.valid ? e.target.value : v)) }}>
                                                            <option value={addcity_id}>Select City</option>
                                                            {cities.map(({ city_name, city_id }) => (
                                                                <option key={city_id} value={city_id}>
                                                                    {city_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="formError">{formErrors.addcity_id}</span>

                                                    </div>
                                                </div>


                                                <div className="form-group row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-8">
                                                        <button type="submit" className="btn btn-primary">Add Address</button>
                                                    </div>

                                                </div>

                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterRevamp />



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>SELECT ADDRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table" widht="100%">
                            <thead>
                                <tr>
                                    <th>ADDRESS</th>
                                    <th>BLK NO</th>
                                    <th>BUILDING</th>
                                    <th>POSTAL CODE</th>
                                    <th>ROAD NAME</th>


                                </tr>
                            </thead>
                            {address.map(({ ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME }) => (
                                <tr>
                                    <td>{ADDRESS}</td>
                                    <td>{BLK_NO}</td>
                                    <td>{BUILDING}</td>
                                    <td>{POSTAL}</td>
                                    <td>{ROAD_NAME}</td>

                                    <td><button className="btn btn-secondary1" data-bs-dismiss="modal" onClick={() => { handleClick(ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME); handleClose(); }}>SELECT</button></td>
                                </tr>
                            ))}
                        </table>
                    </div>

                </Modal.Body>

            </Modal>


            <Modal show={addShow} onHide={handleAddClose}>
                <Modal.Header closeButton>
                    <Modal.Title>SELECT ADDRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table" widht="100%">
                            <thead>
                                <tr>
                                    <th>ADDRESS</th>
                                    <th>BLK NO</th>
                                    <th>BUILDING</th>
                                    <th>POSTAL CODE</th>
                                    <th>ROAD NAME</th>


                                </tr>
                            </thead>
                            {addaddress.map(({ ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME }) => (
                                <tr>
                                    <td>{ADDRESS}</td>
                                    <td>{BLK_NO}</td>
                                    <td>{BUILDING}</td>
                                    <td>{POSTAL}</td>
                                    <td>{ROAD_NAME}</td>

                                    <td><button className="btn btn-secondary1" data-bs-dismiss="modal" onClick={() => { handleAddClick(ADDRESS, BLK_NO, BUILDING, POSTAL, ROAD_NAME); handleAddClose(); }}>SELECT</button></td>
                                </tr>
                            ))}
                        </table>
                    </div>

                </Modal.Body>

            </Modal>


            {/* Success Alert */}
            {/* <Modal
                size="md"
                show={successAlert}
                onHide={(e) => setSuccessAlert(false)}
                backdrop="static"
                keyboard={false}
            >

                <div className="alert alert-success">
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={(e) => { setSuccessAlert(false); navigate('/ManageAllAddress') }}>
                        ×</button>
                    <span className="glyphicon glyphicon-ok"></span> <strong>Success</strong>
                    <hr className="message-inner-separator" />
                    <p>
                        Address Added Successfully.</p>
                </div>

            </Modal> */}


            {/* Failure Alert */}
            {/* <Modal

                show={failureAlert}
                onHide={(e) => setFailureAlert(false)}
                backdrop="static"
                keyboard={false}
                size="md"
            >

                <div className="alert alert-danger">
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={(e) => setFailureAlert(false)}>
                        ×</button>
                    <span className="glyphicon glyphicon-hand-right"></span> <strong>Failure</strong>
                    <hr className="message-inner-separator" />
                    <p>
                        {failureMessage}</p>
                </div>

            </Modal> */}

            <ModalCommon
                show={successShow || failureShow}
                onHide={handleCloseShow}
            >
                {successShow && (
                    <Modal.Header className="modal-head">
                        <div className="icon-box">
                            <i className="fa fa-check icons-1"></i>
                        </div>
                    </Modal.Header>
                )}
                {failureShow && (
                    <Modal.Header className="modal-headd">
                        <div className="icon-box1">
                            <i className="fa fa-exclamation icons-1"></i>
                        </div>
                    </Modal.Header>
                )}

                {(successShow || failureShow) && (
                    <Fragment>
                        <Modal.Body className="text-center">
                            <p>
                                {message}
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="text-right font-weight">
                            <a
                                className="text-blue"
                                data-dismiss="modal"
                                onClick={handleCloseShow}
                            >
                                OK
                            </a>
                        </Modal.Footer>
                    </Fragment>
                )}
            </ModalCommon>
        </>

    )
}

export default AddNewAddress;